import React from 'react';
import './AnimatedLines.css';

const AnimatedLines = () => {
  return (
    <div className="animated-lines-container">
      {[...Array(10)].map((_, i) => (
        <div key={i} className="wave-line">
          <svg viewBox="0 0 100 30" xmlns="http://www.w3.org/2000/svg">
            <path d="M0,15 Q25,5 50,15 T100,15" fill="none" stroke="#222" strokeWidth="2" className={`animated-line line-${i}`} />
          </svg>
        </div>
      ))}
    </div>
  );
};

export default AnimatedLines;