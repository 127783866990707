import './App.css';
import { About, AllProjects,Footer, Music, Projects, Title } from './components';
import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { createHashRouter, RouterProvider } from 'react-router-dom';

function App() {
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const cursorRef = useRef(null);

  useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  useEffect(() => {
    if (cursorRef.current) {
      const offsetY = 10;
      const offsetX = 10; 
      
      cursorRef.current.style.transition = 'none';
      cursorRef.current.style.transform = `translate(${mousePosition.x - offsetX}px, ${mousePosition.y - offsetY}px)`;
      requestAnimationFrame(() => {
        cursorRef.current.style.transition = 'transform 0.5s ease-out';
      });
    }
  }, [mousePosition]);

  useEffect(() => {
    const headerElements = document.querySelectorAll('h1, h2, h3, h4, h5, h6, strong');
    
    const handleMouseMove = (e) => {
      if (cursorRef.current) {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        
        e.currentTarget.style.setProperty('--mouse-x', `${x}px`);
        e.currentTarget.style.setProperty('--mouse-y', `${y}px`);
        
        cursorRef.current.style.mixBlendMode = 'normal';
        cursorRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'; // Transparent black
      }
    };
  
    const handleMouseLeave = (e) => {
      if (cursorRef.current) {
        cursorRef.current.style.mixBlendMode = 'difference';
        cursorRef.current.style.backgroundColor = '#000';
      }
    };
  
    headerElements.forEach(element => {
      // Create a wrapper span for the text content
      const wrapper = document.createElement('span');
      wrapper.className = 'header-wrapper';
      wrapper.innerHTML = element.innerHTML;
      element.innerHTML = '';
      element.appendChild(wrapper);
  
      // Add event listeners to the wrapper
      wrapper.addEventListener('mousemove', handleMouseMove);
      wrapper.addEventListener('mouseleave', handleMouseLeave);
    });
  
    return () => {
      headerElements.forEach(element => {
        const wrapper = element.querySelector('.header-wrapper');
        if (wrapper) {
          wrapper.removeEventListener('mousemove', handleMouseMove);
          wrapper.removeEventListener('mouseleave', handleMouseLeave);
          // Unwrap the content
          element.innerHTML = wrapper.innerHTML;
        }
      });
    };
  }, []);

  useEffect(() => {
    if (cursorRef.current) {
      if (isHovered) {
        cursorRef.current.style.mixBlendMode = 'normal';
        cursorRef.current.style.backgroundColor = '#136F63';
      } else {
        cursorRef.current.style.mixBlendMode = 'difference';
        cursorRef.current.style.backgroundColor = '#000';
      }
    }
  }, [isHovered]);

  const router = createHashRouter([
    {
      path: '/',
      element: (
        <div>
          <motion.div
            className={`cursor ${isHovered ? 'green' : ''}`}
            ref={cursorRef}
          />
          <Title setIsHovered={setIsHovered} />
          <Projects setIsHovered={setIsHovered} />
          <About setIsHovered={setIsHovered} />
          <Music />
          <Footer setIsHovered={setIsHovered} />
        </div>
      ),
    },
    {
      path: 'projects/:projectId',
      element: (
        <div>
          <motion.div
            className={`cursor ${isHovered ? 'green' : ''}`}
            ref={cursorRef}
          />
          <AllProjects setIsHovered={setIsHovered} />
        </div>
      ),
    },
    {
      path: 'pprojects/:pprojectId',
      element: (
        <div>
          <motion.div
            className={`cursor ${isHovered ? 'green' : ''}`}
            ref={cursorRef}
          />
          <AllProjects setIsHovered={setIsHovered} />
        </div>
      ),
    },
  ]);

  return (
    <div className="app">
      <div className="app-container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;