import React from 'react';
import './About.css';

const About = ({ setIsHovered }) => {
  return (
    <div className='spacing-large'>
      <h3>about</h3>
      <p className='spacing-medium'>
        hi, i'm zach, a designer, engineer, and creative technologist. i develop interfaces for new forms of computation aiming to simplify complex creative software tools. graduated from the master of design program <a 
          href="https://www.berkeley.edu/"
          target="_blank"
          rel="noopener noreferrer"
          className="links"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >@berkeley</a>.
      </p>
      <p className='spacing-small'>previously i used to build tech communities in a small corner of south-west england.</p>
    </div>
  );
}

export default About;